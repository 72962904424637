'use client';
// @ts-ignore
import { IKContext } from 'imagekitio-react';

const urlEndpoint = process.env.NEXT_PUBLIC_IMAGEKIT_URL_ENDPOINT ?? '';
const publicKey = process.env.NEXT_PUBLIC_IMAGEKIT_PUBLIC_KEY ?? '';

const authenticator = async () => {
    try {
        const response = await fetch('/api/backoffice/product-editor/image-kit-auth', {
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': process.env.NEXT_PUBLIC_API_KEY ?? '',
            },
        });

        if (!response.ok) {
            const errorText = await response.text();
            throw new Error(`Request failed with status ${response.status}: ${errorText}`);
        }

        const data = await response.json();
        const { signature, expire, token } = data;
        return { signature, expire, token };
    } catch (error: any) {
        throw new Error(`Authentication request failed: ${error.message}`);
    }
};

export type ImageKitContextProps = {
    children: React.ReactNode;
}

export const ImageKitContext = ({ children }: ImageKitContextProps) => {

    return <IKContext
        urlEndpoint={urlEndpoint}
        publicKey={publicKey}
        authenticator={authenticator}
    >
        {children}
    </IKContext>
}